<template>
    <NuxtLayout name="default">
        <template #override>
            <main id="main">
                <slot />
            </main>
        </template>
    </NuxtLayout>
</template>

<script lang="ts" setup>

</script>

<style lang="scss" scoped>

</style>
